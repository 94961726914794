import React, { Component } from 'react';
import Symbols from './Symbols';
import './App.css';

class App extends Component {

  render() {
    return (
      <main className="Scratchy">
        <Symbols />
      </main>
    );
  }
}

export default App;
